import { useState, useEffect, useCallback } from "react";
import { getUsername } from "./api";

interface NotificationMesssage {
    module: string;
    metadata: any;
}
// Custom hook for managing WebSocket connections
function useWebSocket(url: string) {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [isConnected, setIsConnected] = useState(false);

    // Function to send messages
    const sendMessage = useCallback(
        (message: any) => {
            if (socket) {
                socket.send(JSON.stringify(message));
            }
        },
        [socket]
    );

    // Custom event dispatcher
    const dispatchEvent = useCallback((eventData: NotificationMesssage) => {
        switch (eventData.module) {
            case "PONG":
                break;
            case "Notification":
                const event = new CustomEvent("notification-message", { detail: eventData.metadata });
                window.dispatchEvent(event);
                break;
        }
    }, []);

    // Effect to establish WebSocket connection
    useEffect(() => {
        let newSocket = new WebSocket(url);

        const open = () => {
            console.log("WebSocket connection established");
            const username = getUsername();
            newSocket.send(`{"username":"${username}"}`);
            setIsConnected(true);
        };
        newSocket.onopen = open;

        const message = (event: any) => {
            const message = JSON.parse(event.data);
            dispatchEvent(message); // Dispatch an event when a message is received
        };
        newSocket.onmessage = message;

        const close = (why: any) => {
            console.log("WebSocket connection closed:" + why.code);
            setIsConnected(false);
            const newSocket2 = new WebSocket(newSocket.url);
            newSocket2.addEventListener("open", open);
            newSocket2.addEventListener("message", message);
            newSocket2.addEventListener("close", close);
            newSocket = newSocket2
            setSocket(newSocket);
        };
        newSocket.onclose = close;
        
        setSocket(newSocket);

        // setInterval(() => {
        //     if (newSocket.readyState == newSocket.OPEN) newSocket.send("PING");
        // }, 10000);

        // Cleanup function to close WebSocket connection when the component unmounts or url changes
        return () => {
            newSocket.close();
        };
    }, [url, dispatchEvent]);

    return { sendMessage, isConnected };
}

export default useWebSocket;