import { Question } from "../../api/models";
import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "I want shampoo recommendation for my coloured hair, so that they get nourishment without damaging the hair colour. Which shampoo should I use?",
        value: "I want shampoo recommendation for my coloured hair, so that they get nourishment without damaging the hair colour. Which shampoo should I use?"
    },
    { text: "What is the current stock level of the Hydrating Shampoo across all warehouses?", value: "What is the current stock level of the Hydrating Shampoo across all warehouses?" },
    { text: "Analyze feedback trends to recommend hair product improvements or new product ideas.", value: "Analyze feedback trends to recommend hair product improvements or new product ideas." }
];


interface Props {
    onExampleClicked: (value: string) => void;
    list: Question[]
}

export const ExampleList = ({ onExampleClicked,list }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {list?.map((x) => (
                <li key={x.Key}>
                    <Example text={x.Value} value={x.Key} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
