/* tslint:disable */
/* eslint-disable */
/**
 * FormExtract.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportDocumentResponse
 */
export interface ExportDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof ExportDocumentResponse
     */
    location?: string | null;
}

/**
 * Check if a given object implements the ExportDocumentResponse interface.
 */
export function instanceOfExportDocumentResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExportDocumentResponseFromJSON(json: any): ExportDocumentResponse {
    return ExportDocumentResponseFromJSONTyped(json, false);
}

export function ExportDocumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportDocumentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': !exists(json, 'location') ? undefined : json['location'],
    };
}

export function ExportDocumentResponseToJSON(value?: ExportDocumentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': value.location,
    };
}

