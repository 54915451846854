import * as React from "react";
import { Delete24Regular } from "@fluentui/react-icons";
import { Search24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { ActivityItem, IActivityItemProps, Link, Panel, DefaultButton, mergeStyleSets, Checkbox, IPanelStyles } from "@fluentui/react";
import { useEffect, useState } from "react";
import { getDocumentsByUser, removeDocument, addTempNotification } from "../../api";
import { format } from "date-fns/format";
import styles from "./UserDocuments.module.css";

// Function to format a date using date-fns
function formatDate(date: Date) {
    // Example format: January 1, 2024
    return `${format(date, "yyyy-MM-dd HH:mm")}`;
}

export interface UserDocument {
    modified: Date;
    content?: string;
    name: string;
    id: string;
    loaded?: boolean;
}
interface UserDocumentsProps {
    className?: string;
    isOpen?: boolean;
    onClose: () => void;
    onDocumentSelect: (item: UserDocument) => void;
}

export const UserDocuments: React.FC<UserDocumentsProps> = ({ className, isOpen, onClose, onDocumentSelect }) => {
    const [documents, setDocuments] = useState<UserDocument[]>([]);
    const [query, setQuery] = useState<string>("");
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [selectedDocuments, setSelectedDocuments] = useState<UserDocument[]>([]);

    const handleSearch = () => {
        setIsSearching(true);
    };

    const searchResults = documents.filter(item => item.name.toLowerCase().includes(query.toLowerCase()));

    const handleInputChange = (event: any) => {
        setQuery(event.target.value);
        setIsSearching(true);
    };
    const documentAnalysis = (item: UserDocument) => {
        onDocumentSelect(item);
    };
    const customPanelStyles: Partial<IPanelStyles> = {
        root: {
            selectors: {
                ".ms-Panel-contentInner": {
                    backgroundColor: "#232533"
                },
                ".ms-Panel-commands":{
                    backgroundColor: "#232533",
                    color: "white"
                },
                ".ms-Panel-headerText": {
                    color: "white"
                },
                ".ms-Panel-content": {
                    paddingLeft: "0",
                    paddingRight: "0"
                },
                ".ms-Panel-closeButton": {
                    color: "white"
                },
                ".ms-Panel-closeButton:hover": {
                    color: "#232533"
                }
            }
        }
    };
    const removeUserDocument = async (document: UserDocument) => {
        try {
            const response = await removeDocument(document.id);
            if (response.removed) {
                addTempNotification({
                    type: "success",
                    message: `Document ${document.name} is deleted. `
                });
            }
        } catch {
            addTempNotification({
                type: "error",
                message: `Not able to complete your request`
            });
        }
    };

    useEffect(() => {
        const initialCall = async () => {
            const documents = await getDocumentsByUser();
            const userDocuments = documents.pagedItems?.map(doc => {
                const item: UserDocument = {
                    id: doc.id!,
                    modified: doc.modified!,
                    name: doc.name!
                };
                return item;
            });
            setDocuments(userDocuments!);
        };
        const handleMessage = async (event: any) => {
            const documents = await getDocumentsByUser();
            const userDocuments = documents.pagedItems?.map(doc => {
                const item: UserDocument = {
                    id: doc.id!,
                    modified: doc.modified!,
                    name: doc.name!
                };
                return item;
            });

            setDocuments(userDocuments!);
        };
        //initial call
        initialCall();

        // Add event listener for custom websocket-message events
        window.addEventListener("notification-message", handleMessage);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("notification-message", handleMessage);
        };
    }, []);

    return (
        <Panel
            headerText="Documents"
            isOpen={isOpen}
            isBlocking={false}
            onDismiss={onClose}
            closeButtonAriaLabel="Close"
            styles={customPanelStyles}
            //onRenderFooterContent={() => <DefaultButton onClick={onClose}>Close</DefaultButton>}
            isFooterAtBottom={true}
        >
            <div className={styles.mainMainContainer}>
                <div className={styles.searchButtonContainer}>
                    <input className={styles.searchBox} onChange={handleInputChange} type="text" value={query}></input>
                    <Button icon={<Search24Regular className={styles.searchIcon} />} onClick={handleSearch}></Button>
                </div>
                {isSearching
                    ? searchResults.map((item, index) => (
                          <div className={styles.mainContainer} key={index}>
                              <div className={styles.insideContainer}>
                                  <div onClick={() => documentAnalysis(item)} className={styles.fileName}>
                                      {item.name}
                                  </div>
                                  <div>{formatDate(item.modified)}</div>
                              </div>
                              <button className={styles.closeButton} onClick={() => removeUserDocument(item)}>
                                  X
                              </button>
                              {/* <Button icon={<Delete24Regular className={styles.deleteIcon} />} onClick={() => removeUserDocument(item)}></Button> */}
                          </div>
                      ))
                    : documents.map((item, index) => (
                          <div className={styles.mainContainer} key={index}>
                              {/* <input type="checkbox" style={{ marginRight: "14px" }}></input> */}
                              <div className={styles.insideContainer}>
                                  <div onClick={() => documentAnalysis(item)} className={styles.fileName}>
                                      {item.name}
                                  </div>
                                  <div>{formatDate(item.modified)}</div>
                              </div>
                              <button className={styles.closeButton} onClick={() => removeUserDocument(item)}>
                                  X
                              </button>
                              {/* <Button icon={<Delete24Regular className={styles.deleteIcon} />} onClick={() => removeUserDocument(item)}></Button> */}
                          </div>
                      ))}
            </div>
        </Panel>
    );
};
