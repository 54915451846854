import styles from "./UserChatMessage.module.css";
import { UserChatMessageIcon } from "./UserChatMessageIcon";

interface Props {
    message: string;
}

export const UserChatMessage = ({ message }: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.message}>{message}</div>
            <div className={styles.userIconContainer}>
                <UserChatMessageIcon />
            </div>
        </div>
    );
};
