import { useMemo, useState } from "react";
import { Stack, IconButton, IButtonStyles } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import { ChatAppResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onPlayContentClicked: (response: string) => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onPlayContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions
}: Props) => {
    const [isAudioPlaying, setIsAudioPlaying] = useState<Boolean>(false);
    const followupQuestions = answer.choices[0].context.followup_questions;
    const messageContent = answer.choices[0].message.content;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked), [answer]);
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    const customIconButtonStyles: IButtonStyles = {
        root: {
          backgroundColor: 'transparent', 
          selectors: {
            ':hover': {
              backgroundColor: 'white', 
              selectors: {
                '.ms-Button-icon': {
                  color: '#2E303E', 
                },
              },
            },
          },
        },
        icon: {
          color: 'white', 
        },
      };
    return (
        <Stack>
            <Stack className={`${styles.answerMainContainer}`}>
                <Stack className={`${styles.answerIconContainer}`}>
                    <AnswerIcon />
                </Stack>
                <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
                    <Stack.Item grow>
                        <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                    </Stack.Item>

                    {!!parsedAnswer.citations.length && (
                        <Stack.Item>
                            <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                <span className={styles.citationLearnMore}>Citations:</span>
                                {parsedAnswer.citations.map((x, i) => {
                                    const path = getCitationFilePath(x[0]);
                                    return (
                                        <a key={i} className={styles.citation} title={x[1]} onClick={() => onCitationClicked(path)}>
                                            {`${++i}. ${x[1]}`}
                                        </a>
                                    );
                                })}
                            </Stack>
                        </Stack.Item>
                    )}

                    {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                        <Stack.Item>
                            <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                                <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                                {followupQuestions.map((x, i) => (
                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                        {`${x}`}
                                    </a>
                                ))}
                            </Stack>
                        </Stack.Item>
                    )}
                </Stack>
            </Stack>
            <Stack className={`${styles.buttonContainer}`} horizontal horizontalAlign="space-between">
                
                <div>
                    <IconButton
                        style={{ color: "white" }}
                        iconProps={{ iconName: "Lightbulb" }}
                        title="Show thought process"
                        ariaLabel="Show thought process"
                        onClick={() => onThoughtProcessClicked()}
                        styles={customIconButtonStyles}
                        disabled={!answer.choices[0].context.thoughts?.length}
                    />
                    <IconButton
                        style={{ color: "white" }}
                        iconProps={{ iconName: "ClipboardList" }}
                        title="Show supporting content"
                        ariaLabel="Show supporting content"
                        onClick={() => onSupportingContentClicked()}
                        styles={customIconButtonStyles}
                        disabled={!answer.choices[0].context.thoughts?.length}
                    />
                    {
                        isAudioPlaying ? (
                            <IconButton
                                style={{ color: "white" }}
                                iconProps={{ iconName: "VolumeDisabled" }}
                                title="Stop Reading Out Loud"
                                ariaLabel="Stop Reading Out Loud"
                                onClick={() => {
                                    if ('speechSynthesis' in window) {
                                        window.speechSynthesis.cancel();
                                        setIsAudioPlaying(false);
                                    } else {
                                        alert('Sorry, your browser does not support speech synthesis.');
                                    }
                                }}
                                styles={customIconButtonStyles}
                                disabled={!answer.choices[0].context.thoughts?.length}
                            />
                        ) : (
                            <IconButton
                                style={{ color: "white" }}
                                iconProps={{ iconName: "ReadOutLoud" }}
                                title="Read Out Loud"
                                ariaLabel="Read Out Loud"
                                onClick={() => {
                                    if ('speechSynthesis' in window) {
                                        const utterance = new SpeechSynthesisUtterance(messageContent);
                                        utterance.onend = () => {
                                            setIsAudioPlaying(false);
                                        };
                                        window.speechSynthesis.speak(utterance);
                                        setIsAudioPlaying(true);
                                    } else {
                                        alert('Sorry, your browser does not support speech synthesis.');
                                    }
                                }}
                                styles={customIconButtonStyles}
                                disabled={!answer.choices[0].context.thoughts?.length}
                            />
                        )
                    }
                    <IconButton
                        style={{ color: "white" }}
                        iconProps={{ iconName: "Copy" }}
                        title="Copy Response"
                        ariaLabel="Copy Response"
                        onClick={() => navigator.clipboard.writeText(messageContent)}
                        styles={customIconButtonStyles}
                        disabled={!answer.choices[0].context.thoughts?.length}
                    />
                </div>
            </Stack>
        </Stack>
    );
};
