import React, { useState } from "react";
import { Modal, IconButton, IIconProps, Checkbox, PrimaryButton, Stack, Label, IStackTokens, MessageBarType } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FluentProvider } from "@fluentui/react-components";
import lightTheme from "../..";
import { ChatConfiguration, getUploadSettings } from "../../api";
import Loader from "../../assets/loader.gif";
import { ArrowExitRegular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { useMessages } from "../NotificationBar";
import { chatConfiguration } from "../../api";
import { color } from "@chakra-ui/react";
const RYZEREADER_URI = import.meta.env.VITE_RYZEREADER_URI != null ? import.meta.env.VITE_RYZEREADER_URI : window.location.origin;

// Styling for the component
const styles = `
  #fileInput::file-selector-button {
    background-color: #6912D9;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  #fileInput::file-selector-button:hover {
    background-color: #5a0cbf;
  }

  #fileInput::file-selector-button:active {
    background-color: #4a0aa0;
  }
`;
const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        borderRadius: "0",
        backgroundColor: "#2E303E",
        color: "white",
        width: "100%",
        maxWidth: "350px",
    },
    header: {
        // flex: "1 1 auto",
        display: "flex",
        justifyContent: "space between",
        alignItems: "center",
        padding: "8%",
        
    },
    headerTitle: {
        flex: "1",
        color: "white",
        fontSize: "20px",
    },
    closeButton: {
        color: "rgb(243, 242, 241)",
        ':hover' : {
            color: "rgb(50, 49, 48)",
            backgroundColor: "rgb(243, 242, 241)"
        }
    },
    body: {
        flex: "4 4 auto",
        padding: "0 8% 4% 8%",
        overflowY: "hidden",
        backgroundColor: "#2E303E",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 }
        }
    },
    content: {
        color: "#989CA8",
        fontSize: "10px",
        marginTop: "10px !important",
        marginBottom: "10px !important",
    },
    loaderHolder: {
        display: "flex",
        alignItems: "center",
    },
});

const stackTokens: IStackTokens = { childrenGap: 20 };

// Close icon for the modal
const cancelIcon: IIconProps = { iconName: "Cancel" };
interface Props {
    chatConfiguration: ChatConfiguration;
}

const UploadDialogModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
    const [isPublic, setIsPublic] = useState(false);
    const [files, setFiles] = useState(Array<File>);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [progressMessage, setProgressMessage] = useState<string>("");
    const { addMessage, sendNotification } = useMessages();

    // Function to handle file selection
    const extEnabled = ".doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.json,.pdf,.gif,.jpg,.png";
    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        // const ext = file?.name.split(".")[1];
        // const isSupported = extEnabled.indexOf(ext!) >= 0;
        const ext = file?.name.split(".").pop();
        const isSupported = extEnabled.indexOf(`.${ext}`) >= 0;
        setFiles([]);
        if (file && isSupported) {
            //files.push(file);
            setFiles([file]);
        } else {
            setFiles([]);
            sendNotification({
                message: "This file extension is not supported, please choose another file.",
                type: "warning"
            });
        }
    };
    const handleUpload = async (event: any) => {
        try {
            setProgressMessage("In Progress...");
            setInProgress(true);
            const file = files[0];
            const uploadSettings = getUploadSettings();
            if (file) {
                let formData = new FormData();
                formData.append("file", file);
                let response = await fetch(
                    `${RYZEREADER_URI}/projects/${chatConfiguration?.projectId}/documents/ryze-upload?&username=${chatConfiguration?.username}&isPublic=${isPublic}`,
                    {
                        method: "POST",
                        body: formData
                    }
                );
                setFiles([]);
                const contentLength = response.headers.get("content-length") ?? "0"; // Provide a default value
                const total = parseInt(contentLength, 10);
                let loaded = 0;

                const res = new Response(
                    new ReadableStream<Uint8Array>({
                        async start(controller) {
                            const reader = response.body!.getReader();
                            for (;;) {
                                const { done, value } = await reader.read();
                                if (done) break;
                                loaded += value.byteLength;
                                controller.enqueue(value);
                            }
                            controller.close();
                        }
                    })
                );
                // Process the file here
            }
            setProgressMessage("The file has been uploaded and is now ready for any questions or feedback.");

            addMessage({
                message: "Processing Document. Feel free to ask any questions once it's ready! ",
                type: "warning" // Or any logic to determine the message type
            });

            setTimeout(() => {
                onClose();
            }, 1500);
        } catch {
            setProgressMessage("Not able to upload your file now. Try again later.");
        } finally {
            setInProgress(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onClose} isBlocking={false} containerClassName={contentStyles.container}
        styles={{
            root: {
              backgroundColor: "rgb(0 0 0 / 80%)",
            },
          }}>
            <div className={contentStyles.header}>
                <div className={contentStyles.headerTitle}>
                    <strong>Upload Document</strong>
                </div>
                <div>
                    <IconButton className={contentStyles.closeButton} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={onClose} />
                </div>
            </div>
            <FluentProvider theme={lightTheme}>
                <div className={contentStyles.body}>
                    <Stack tokens={stackTokens}>
                        <Label style={{ color: "white", padding: "0", fontSize: "15px" }} htmlFor="fileInput">Select a File</Label>
                        
                            <input
                                className={styles}
                                style={{ borderBlock: "2px", color: "white" }}
                                id="fileInput"
                                accept=".xls,.xlsx,.doc,.docx,.csv,.json,.png,.jpg,.gif,.ppt,.pptx,.pdf"
                                type="file"
                                onChange={handleFileSelect}
                            />
                            <p className={contentStyles.content}>Supported files (Documents, Images) Maximum file size 20MB</p>
                        
                        
                            <Checkbox label="Is Public" checked={isPublic} onChange={(e, checked) => setIsPublic(!!checked)}  styles={{
                                root: {
                                    color: "white",
                                    ".ms-Checkbox-label":{
                                        color: "white"
                                    },
                                    selectors: {
                                        '.ms-Checkbox': {
                                            color: "white",
                                            ':hover .ms-Checkbox-checkbox': {
                                                border: "1px solid white",
                                                color: "white",
                                            },
                                            ':hover .ms-Checkbox-checkmark': {
                                                color: "white",
                                                opacity: "1",
                                            },
                                        },
                                        '.ms-Checkbox-checkbox': {
                                            border: "1px solid white",
                                            color: "white",
                                        },
                                        '.ms-Checkbox-checkmark': {
                                            color: "white",
                                            opacity: "1",
                                        },
                                        '.ms-Checkbox-text': {
                                            color: "white",
                                            ':hover': {
                                                color: "white",
                                            }
                                        },
                                        'input:checked + .ms-Checkbox-label::before': {
                                            color: "white",
                                            borderColor: "white",
                                        },
                                        
                                    },
                                },
                            }}/>
                        

                        <PrimaryButton text="Upload" onClick={handleUpload} 
                        styles={{
                            root: {
                                width: "50%",
                                height: "auto",
                                padding: "5% 0",
                                backgroundColor: '#6912D9',
                                borderColor: '#6912D9',
                                color: 'white',
                                borderRadius: "5px",
                                selectors: {
                                ':hover': {
                                  backgroundColor: '#5a0cbf',
                                  border: "1px solid #5a0cbf",
                                },
                                ':active': {
                                  backgroundColor: '#5a0cbf',
                                  border: "1px solid #5a0cbf",
                                },
                              },
                            },
                            rootHovered: {
                              backgroundColor: '#5a0cbf',
                              border: "1px solid #5a0cbf",
                            },
                            rootPressed: {
                              backgroundColor: '#5a0cbf',
                              border: "1px solid #5a0cbf",
                            },
                          }}/>
                        <div>
                            {inProgress ? (
                                <div style={{ display: "flex", alignItems: "center" }} className={contentStyles.loaderHolder}>
                                    <img width={"20px"} height={"20px"} style={{}} src={Loader} />
                                    <div style={{ fontFamily: "sans-serif" }}>{progressMessage}</div>
                                </div>
                            ) : null}
                        </div>
                    </Stack>
                </div>
            </FluentProvider>
        </Modal>
    );
};

export default UploadDialogModal;
