/* tslint:disable */
/* eslint-disable */
/**
 * FormExtract.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Document } from './Document';
import {
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
} from './Document';

/**
 * 
 * @export
 * @interface UploadDocumentResponse
 */
export interface UploadDocumentResponse {
    /**
     * 
     * @type {Document}
     * @memberof UploadDocumentResponse
     */
    createdDocument?: Document;
    /**
     * 
     * @type {string}
     * @memberof UploadDocumentResponse
     */
    partitionKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadDocumentResponse
     */
    rowKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadDocumentResponse
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadDocumentResponse
     */
    filename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadDocumentResponse
     */
    projectId?: string | null;
}

/**
 * Check if a given object implements the UploadDocumentResponse interface.
 */
export function instanceOfUploadDocumentResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UploadDocumentResponseFromJSON(json: any): UploadDocumentResponse {
    return UploadDocumentResponseFromJSONTyped(json, false);
}

export function UploadDocumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadDocumentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDocument': !exists(json, 'createdDocument') ? undefined : DocumentFromJSON(json['createdDocument']),
        'partitionKey': !exists(json, 'partitionKey') ? undefined : json['partitionKey'],
        'rowKey': !exists(json, 'rowKey') ? undefined : json['rowKey'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
    };
}

export function UploadDocumentResponseToJSON(value?: UploadDocumentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDocument': DocumentToJSON(value.createdDocument),
        'partitionKey': value.partitionKey,
        'rowKey': value.rowKey,
        'contentType': value.contentType,
        'filename': value.filename,
        'projectId': value.projectId,
    };
}

