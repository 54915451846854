/* tslint:disable */
/* eslint-disable */
/**
 * FormExtract.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Document } from './Document';
import {
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
} from './Document';

/**
 * 
 * @export
 * @interface ReprocessDocumentResponse
 */
export interface ReprocessDocumentResponse {
    /**
     * 
     * @type {Document}
     * @memberof ReprocessDocumentResponse
     */
    document?: Document;
}

/**
 * Check if a given object implements the ReprocessDocumentResponse interface.
 */
export function instanceOfReprocessDocumentResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReprocessDocumentResponseFromJSON(json: any): ReprocessDocumentResponse {
    return ReprocessDocumentResponseFromJSONTyped(json, false);
}

export function ReprocessDocumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReprocessDocumentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document': !exists(json, 'document') ? undefined : DocumentFromJSON(json['document']),
    };
}

export function ReprocessDocumentResponseToJSON(value?: ReprocessDocumentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document': DocumentToJSON(value.document),
    };
}

