/* tslint:disable */
/* eslint-disable */
/**
 * FormExtract.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimeSpan } from './TimeSpan';
import {
    TimeSpanFromJSON,
    TimeSpanFromJSONTyped,
    TimeSpanToJSON,
} from './TimeSpan';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    projectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentStatusEvent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    readonly storagePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    readonly storagePathKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    readonly pagesStoragePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    readonly documentStoragePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    readonly extension?: string | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof Document
     */
    extracted?: TimeSpan;
    /**
     * 
     * @type {TimeSpan}
     * @memberof Document
     */
    transformed?: TimeSpan;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    partitionKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    rowKey?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    timestamp?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    totalPages?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    coverUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    isReprocess?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    username?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    isPublic?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    isRyzeDocument?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    gptIndex?: string | null;
}

/**
 * Check if a given object implements the Document interface.
 */
export function instanceOfDocument(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentFromJSON(json: any): Document {
    return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'documentGroupId': !exists(json, 'documentGroupId') ? undefined : json['documentGroupId'],
        'documentStatus': !exists(json, 'documentStatus') ? undefined : json['documentStatus'],
        'documentStatusEvent': !exists(json, 'documentStatusEvent') ? undefined : json['documentStatusEvent'],
        'storagePath': !exists(json, 'storagePath') ? undefined : json['storagePath'],
        'storagePathKey': !exists(json, 'storagePathKey') ? undefined : json['storagePathKey'],
        'pagesStoragePath': !exists(json, 'pagesStoragePath') ? undefined : json['pagesStoragePath'],
        'documentStoragePath': !exists(json, 'documentStoragePath') ? undefined : json['documentStoragePath'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'extracted': !exists(json, 'extracted') ? undefined : TimeSpanFromJSON(json['extracted']),
        'transformed': !exists(json, 'transformed') ? undefined : TimeSpanFromJSON(json['transformed']),
        'partitionKey': !exists(json, 'partitionKey') ? undefined : json['partitionKey'],
        'rowKey': !exists(json, 'rowKey') ? undefined : json['rowKey'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (json['timestamp'] === null ? null : new Date(json['timestamp'])),
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'coverUrl': !exists(json, 'coverUrl') ? undefined : json['coverUrl'],
        'isReprocess': !exists(json, 'isReprocess') ? undefined : json['isReprocess'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'isPublic': !exists(json, 'isPublic') ? undefined : json['isPublic'],
        'isRyzeDocument': !exists(json, 'isRyzeDocument') ? undefined : json['isRyzeDocument'],
        'gptIndex': !exists(json, 'gptIndex') ? undefined : json['gptIndex'],
    };
}

export function DocumentToJSON(value?: Document | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'createdBy': value.createdBy,
        'modifiedBy': value.modifiedBy,
        'name': value.name,
        'projectId': value.projectId,
        'documentGroupId': value.documentGroupId,
        'documentStatus': value.documentStatus,
        'documentStatusEvent': value.documentStatusEvent,
        'extracted': TimeSpanToJSON(value.extracted),
        'transformed': TimeSpanToJSON(value.transformed),
        'partitionKey': value.partitionKey,
        'rowKey': value.rowKey,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp === null ? null : value.timestamp.toISOString()),
        'totalPages': value.totalPages,
        'coverUrl': value.coverUrl,
        'isReprocess': value.isReprocess,
        'username': value.username,
        'isPublic': value.isPublic,
        'isRyzeDocument': value.isRyzeDocument,
        'gptIndex': value.gptIndex,
    };
}

