import * as React from "react";
import { useState, useEffect } from "react";
import { Input, Button, Field, createLightTheme, createDarkTheme } from "@fluentui/react-components";
import styles from "./Login.module.scss";
import { getToken } from "../../api/sso";
import {  FluentProvider } from "@fluentui/react-components";
import type { BrandVariants, Theme } from "@fluentui/react-components";
import lightTheme from "../..";
 
 
export const LoginForm: React.FC = (props: any) => {
    console.log(props)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoginError, setIsLoginError] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
   
 
    useEffect(() => {
        const message = window.localStorage.getItem("SIGN_OUT_SUCCESS");
        if (message) {
            setSuccessMessage(message);
            window.localStorage.removeItem("SIGN_OUT_SUCCESS");
           
            const timer = setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
 
           
            return () => clearTimeout(timer);
        }
    }, []);
 
    const handleSubmit = async () => {
        // Implement login logic here
        try {
            setIsLoginError(false);
            const response = await getToken(email, password);
            window.localStorage.setItem("RYZE_TOKEN", response.tokenGeneration.accessToken);
            window.localStorage.setItem("RYZE_USER", JSON.stringify(response));
            window.location.href = "/#chat";
            window.localStorage.removeItem("SIGN_OUT_SUCCESS");
        } catch {
            setIsLoginError(true);
        }
    };
 
    const handleRequestAccess = () => {
        // Implement access request logic here
        console.log("Requesting access...");
    };
   
    return (
        <FluentProvider theme={lightTheme} className={styles.FluentProviderOverrides}>
            <div className={styles.root}>
                <div className={styles.container}>
                    {successMessage && (
                        <div className={styles.SuccessMessage}>
                            <span>{successMessage}</span>
                        </div>
                    )}
                    <div className={styles.Inputs}>
                        <div className={styles.iconAsset}>
                        </div>
                        <div className={styles.fdRyze}>
                        </div>
                        <div className={styles.Username}>
                            {/* <Field label="Username">
                                <Input className={styles.inputElement} name='username-login' onChange={e => setEmail(e.target.value)} />
                            </Field> */}
                            <Input className={styles.inputElement} name='username-login' onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className={styles.Password}>
                            {/* <Field label="Password">
                                <Input className={styles.inputElement} type="password" name="password-login" onChange={e => setPassword(e.target.value)} />
                            </Field> */}
                            <Input className={styles.inputElement} type="password" name="password-login" onChange={e => setPassword(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles.Buttons}>
                        <div className={styles.LoginButton}>
                            {isLoginError && (
                                <div className={styles.ErrorMessage}>
                                    <span>Current credentials are not valid.</span>
                                </div>
                            )}
                            <Button name='submit-login' appearance="primary" type="submit" value="" onClick={handleSubmit}>
                                Login
                            </Button>
                            {/* <Button appearance="primary" value="Request Access" onClick={handleRequestAccess}>
                                Request Access
                            </Button> */}
                        </div>
                    </div>
                </div>
            </div>
        </FluentProvider>
    );
};
export default LoginForm;