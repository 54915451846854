/* tslint:disable */
/* eslint-disable */
/**
 * FormExtract.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Document } from './Document';
import {
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
} from './Document';

/**
 * 
 * @export
 * @interface GetDocumentsResponse
 */
export interface GetDocumentsResponse {
    /**
     * 
     * @type {Array<Document>}
     * @memberof GetDocumentsResponse
     */
    items?: Array<Document> | null;
    /**
     * 
     * @type {string}
     * @memberof GetDocumentsResponse
     */
    continuationToken?: string | null;
    /**
     * 
     * @type {Array<Document>}
     * @memberof GetDocumentsResponse
     */
    pagedItems?: Array<Document> | null;
    /**
     * 
     * @type {number}
     * @memberof GetDocumentsResponse
     */
    pageCount?: number;
}

/**
 * Check if a given object implements the GetDocumentsResponse interface.
 */
export function instanceOfGetDocumentsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetDocumentsResponseFromJSON(json: any): GetDocumentsResponse {
    return GetDocumentsResponseFromJSONTyped(json, false);
}

export function GetDocumentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDocumentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(DocumentFromJSON)),
        'continuationToken': !exists(json, 'continuationToken') ? undefined : json['continuationToken'],
        'pagedItems': !exists(json, 'pagedItems') ? undefined : (json['pagedItems'] === null ? null : (json['pagedItems'] as Array<any>).map(DocumentFromJSON)),
        'pageCount': !exists(json, 'pageCount') ? undefined : json['pageCount'],
    };
}

export function GetDocumentsResponseToJSON(value?: GetDocumentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(DocumentToJSON)),
        'continuationToken': value.continuationToken,
        'pagedItems': value.pagedItems === undefined ? undefined : (value.pagedItems === null ? null : (value.pagedItems as Array<any>).map(DocumentToJSON)),
        'pageCount': value.pageCount,
    };
}

