/* tslint:disable */
/* eslint-disable */
/**
 * FormExtract.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExportDocumentRequest,
  ExportDocumentResponse,
  GetDocumentResponse,
  GetDocumentStorageUrlResponse,
  GetDocumentsResponse,
  RemoveDocumentRequest,
  RemoveDocumentResponse,
  ReprocessDocumentRequest,
  ReprocessDocumentResponse,
  UpdateDocumentRequest,
  UpdateDocumentResponse,
  UploadDocumentResponse,
} from '../models/index';
import {
    ExportDocumentRequestFromJSON,
    ExportDocumentRequestToJSON,
    ExportDocumentResponseFromJSON,
    ExportDocumentResponseToJSON,
    GetDocumentResponseFromJSON,
    GetDocumentResponseToJSON,
    GetDocumentStorageUrlResponseFromJSON,
    GetDocumentStorageUrlResponseToJSON,
    GetDocumentsResponseFromJSON,
    GetDocumentsResponseToJSON,
    RemoveDocumentRequestFromJSON,
    RemoveDocumentRequestToJSON,
    RemoveDocumentResponseFromJSON,
    RemoveDocumentResponseToJSON,
    ReprocessDocumentRequestFromJSON,
    ReprocessDocumentRequestToJSON,
    ReprocessDocumentResponseFromJSON,
    ReprocessDocumentResponseToJSON,
    UpdateDocumentRequestFromJSON,
    UpdateDocumentRequestToJSON,
    UpdateDocumentResponseFromJSON,
    UpdateDocumentResponseToJSON,
    UploadDocumentResponseFromJSON,
    UploadDocumentResponseToJSON,
} from '../models/index';

export interface ExportDocumentOperationRequest {
    projectId: string;
    id: string;
    exportDocumentRequest?: ExportDocumentRequest;
}

export interface GetDocumentRequest {
    documentId: string;
    projectId: string;
    id: string;
}

export interface GetDocumentStorageUrlRequest {
    projectId: string;
    id: string;
}

export interface GetDocumentsRequest {
    projectId: string;
    documentGroupId?: string;
    documentStatus?: Array<string>;
    username?: string;
    name?: string;
    sort?: string;
    pageSize?: string;
    pageNumber?: string;
}

export interface PatchDocumentRequest {
    projectId: string;
    id: string;
    updateDocumentRequest?: UpdateDocumentRequest;
}

export interface RemoveDocumentOperationRequest {
    projectId: string;
    id: string;
    removeDocumentRequest?: RemoveDocumentRequest;
}

export interface ReprocessDocumentsRequest {
    projectId: string;
    reprocessDocumentRequest?: ReprocessDocumentRequest;
}

export interface UploadDocumentsRequest {
    projectId: string;
    documentGroupName?: string;
    file?: Array<Blob>;
}

export interface UploadDocumentsRyzeRequest {
    projectId: string;
    username?: string;
    isPublic?: boolean;
    file?: Array<Blob>;
}

/**
 * 
 */
export class DocumentsApi extends runtime.BaseAPI {

    /**
     */
    async exportDocumentRaw(requestParameters: ExportDocumentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportDocumentResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling exportDocument.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling exportDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/projects/{projectId}/documents/{id}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportDocumentRequestToJSON(requestParameters.exportDocumentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportDocumentResponseFromJSON(jsonValue));
    }

    /**
     */
    async exportDocument(requestParameters: ExportDocumentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportDocumentResponse> {
        const response = await this.exportDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDocumentRaw(requestParameters: GetDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDocumentResponse>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocument.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getDocument.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/projects/{projectId}/documents/{id}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDocumentResponseFromJSON(jsonValue));
    }

    /**
     */
    async getDocument(requestParameters: GetDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDocumentResponse> {
        const response = await this.getDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDocumentStorageUrlRaw(requestParameters: GetDocumentStorageUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDocumentStorageUrlResponse>> {
        
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getDocumentStorageUrl.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDocumentStorageUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/projects/{projectId}/documents/{id}/url`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDocumentStorageUrlResponseFromJSON(jsonValue));
    }

    /**
     */
    async getDocumentStorageUrl(requestParameters: GetDocumentStorageUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDocumentStorageUrlResponse> {
        const response = await this.getDocumentStorageUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDocumentsRaw(requestParameters: GetDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDocumentsResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentGroupId !== undefined) {
            queryParameters['documentGroupId'] = requestParameters.documentGroupId;
        }

        if (requestParameters.documentStatus) {
            queryParameters['documentStatus'] = requestParameters.documentStatus;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/projects/{projectId}/documents`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDocumentsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getDocuments(requestParameters: GetDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDocumentsResponse> {
        const response = await this.getDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async patchDocumentRaw(requestParameters: PatchDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateDocumentResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling patchDocument.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patchDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/projects/{projectId}/documents/{id}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentRequestToJSON(requestParameters.updateDocumentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateDocumentResponseFromJSON(jsonValue));
    }

    /**
     */
    async patchDocument(requestParameters: PatchDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateDocumentResponse> {
        const response = await this.patchDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeDocumentRaw(requestParameters: RemoveDocumentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RemoveDocumentResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling removeDocument.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/projects/{projectId}/documents/{id}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveDocumentRequestToJSON(requestParameters.removeDocumentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RemoveDocumentResponseFromJSON(jsonValue));
    }

    /**
     */
    async removeDocument(requestParameters: RemoveDocumentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RemoveDocumentResponse> {
        const response = await this.removeDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reprocessDocumentsRaw(requestParameters: ReprocessDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReprocessDocumentResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling reprocessDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/projects/{projectId}/documents/reprocess`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReprocessDocumentRequestToJSON(requestParameters.reprocessDocumentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReprocessDocumentResponseFromJSON(jsonValue));
    }

    /**
     */
    async reprocessDocuments(requestParameters: ReprocessDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReprocessDocumentResponse> {
        const response = await this.reprocessDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadDocumentsRaw(requestParameters: UploadDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UploadDocumentResponse>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling uploadDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentGroupName !== undefined) {
            queryParameters['documentGroupName'] = requestParameters.documentGroupName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file) {
            requestParameters.file.forEach((element) => {
                formParams.append('file', element as any);
            })
        }

        const response = await this.request({
            path: `/projects/{projectId}/documents/upload`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploadDocumentResponseFromJSON));
    }

    /**
     */
    async uploadDocuments(requestParameters: UploadDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UploadDocumentResponse>> {
        const response = await this.uploadDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadDocumentsRyzeRaw(requestParameters: UploadDocumentsRyzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UploadDocumentResponse>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling uploadDocumentsRyze.');
        }

        const queryParameters: any = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        if (requestParameters.isPublic !== undefined) {
            queryParameters['isPublic'] = requestParameters.isPublic;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file) {
            requestParameters.file.forEach((element) => {
                formParams.append('file', element as any);
            })
        }

        const response = await this.request({
            path: `/projects/{projectId}/documents/ryze-upload`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploadDocumentResponseFromJSON));
    }

    /**
     */
    async uploadDocumentsRyze(requestParameters: UploadDocumentsRyzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UploadDocumentResponse>> {
        const response = await this.uploadDocumentsRyzeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
