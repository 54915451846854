import { Outlet, NavLink, Link } from "react-router-dom";

import github from "../../assets/github.svg";

import styles from "./Layout.module.scss";

import { useLogin } from "../../authConfig";

import { LoginButton } from "../../components/LoginButton";
import { Theme, FluentProvider, FluentProviderProps, makeStyles } from "@fluentui/react-components";

export interface ContentProps {
    lightTheme: Theme;
    darkTheme: Theme;
}

const Layout = (props: any) => {
    console.log(props)
    return (
            <div className={styles.layout}>
                <header className={styles.header} role={"banner"}>
                    <div className={styles.headerContainer}>
                        <Link to="/" className={styles.headerTitleContainer}>
                            {/* <h3 className={styles.headerTitle}>GPT + Enterprise data | Sample</h3> */}
                        </Link>
                        <nav>
                            <ul className={styles.headerNavList}></ul>
                        </nav>

                        {useLogin && <LoginButton />}
                    </div>
                </header>
                <Outlet />
            </div>
    );
};

export default Layout;
