import { Settings24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./SettingsButton.module.css";

interface Props {
    className?: string;
    buttonName?: string;
    onClick: () => void;
}

export const SettingsButton = ({ className, onClick, buttonName }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            
            <Button icon={<Settings24Regular className={styles.icon}/>} onClick={onClick}>
            <div style={{marginLeft : "10px"}}>
            <div className={styles.textContent}>
                  {buttonName}
                  </div>
                </div>
            </Button>
        </div>
    );
};
