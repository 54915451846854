/* tslint:disable */
/* eslint-disable */
/**
 * FormExtract.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDocumentRequest
 */
export interface UpdateDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequest
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequest
     */
    documentGroupId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UpdateDocumentRequest
     */
    extractedComplete?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequest
     */
    coverUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequest
     */
    documentStatusEvent?: string | null;
}

/**
 * Check if a given object implements the UpdateDocumentRequest interface.
 */
export function instanceOfUpdateDocumentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateDocumentRequestFromJSON(json: any): UpdateDocumentRequest {
    return UpdateDocumentRequestFromJSONTyped(json, false);
}

export function UpdateDocumentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDocumentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'documentGroupId': !exists(json, 'documentGroupId') ? undefined : json['documentGroupId'],
        'extractedComplete': !exists(json, 'extractedComplete') ? undefined : (json['extractedComplete'] === null ? null : new Date(json['extractedComplete'])),
        'coverUrl': !exists(json, 'coverUrl') ? undefined : json['coverUrl'],
        'documentStatusEvent': !exists(json, 'documentStatusEvent') ? undefined : json['documentStatusEvent'],
    };
}

export function UpdateDocumentRequestToJSON(value?: UpdateDocumentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'documentGroupId': value.documentGroupId,
        'extractedComplete': value.extractedComplete === undefined ? undefined : (value.extractedComplete === null ? null : value.extractedComplete.toISOString()),
        'coverUrl': value.coverUrl,
        'documentStatusEvent': value.documentStatusEvent,
    };
}

