const F1_SSO_URI = import.meta.env.VITE_F1_SSO_URI;
function fetchPost(url: string, body: any){
    return fetch(url,{
        method:'POST',
        headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
        body:JSON.stringify(body)
    })
}
export async function getToken(username: string, password: string) {
    const response = await fetchPost(`${F1_SSO_URI}/api/generatetoken`, {
            email: username,
            password: password,
            clientID: "F1",
            roleId: 1
        });
    return await response.json();
}
