import { SignOut24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./SignOutButton.module.css";

interface Props {
    className?: string;
    buttonName?: string;
    onClick: () => void;
}

export const SignOutButton = ({ className, onClick, buttonName }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>

            <Button icon={<SignOut24Regular className={styles.icon}/>} onClick={onClick}>
            <div style={{marginLeft : "32px"}}>
            <div className={styles.textContent}>
                  {buttonName}
                  </div>
                </div>
            </Button>
        </div>
    );
};