// useMessages.ts
import { useState, useEffect, useCallback } from "react";
import { MessageBarType } from "@fluentui/react";

// Define the type for a single message
export interface Message {
    id: number;
    message: string;
    messageBarType: MessageBarType;
}

export const useMessages = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const addMessage = (newMessage: { type: string; message: string }) => {
        const event = new CustomEvent("notification-message", { detail: newMessage });
        window.dispatchEvent(event);
    };
    const sendNotification = (newMessage: { type: string; message: string }) => {
        const event = new CustomEvent("notification-message", { detail: newMessage });
        window.dispatchEvent(event);
    };

    

    return { messages, addMessage, sendNotification };
};
